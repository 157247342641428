import React, { useEffect } from 'react';
import { useStorePersisted } from './state/store';
import { useColorMode, useColorModeValue, IconButton } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';

export const ColorModeSwitcher = props => {
  const { colorMode,toggleColorMode, setColorMode } = useColorMode();
  const text = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const {
    theme,
    setTheme
  } = useStorePersisted(state => state);

  const onThemeChangeHandler = () => {
    setTheme(text);
    toggleColorMode();
  };

  useEffect(() => {
    console.log('theme', theme, colorMode);
    if(theme !== colorMode)
      setColorMode(theme);
  }, [colorMode, setColorMode, theme]);
  

  return (
    <>
      <IconButton
        size="md"
        fontSize="lg"
        aria-label={`Switch to ${text} mode`}
        variant="ghost"
        color="current"
        marginLeft="2"
        onClick={onThemeChangeHandler}
        icon={<SwitchIcon />}
        {...props}
      />
    </>
  );
};
