import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import _ from 'lodash';
import ProgrammeCardSkeleton from '../../components/ProgrammeCardSkeleton';
import ProgrammeList from '../../components/ProgrammeList';
import { API_URL, LOAD_LIMIT, SKELETON_SESSIONS } from '../../constants';

import { useStore, useStorePersisted } from '../../state/store';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import isOnline from '../../utils/isOnline';
import ReactGA from 'react-ga';

function Locations() {
  const navigate = useNavigate();
  const { accessCode } = useParams();
  // Persisted state to local storage
  const { allProgrammes, setAllProgrammes, setProgrammeClickedOn } =
    useStorePersisted(state => state);

  // In memory state
  const {
    renderedProgrammes,
    setRenderedProgrammes,
    paginationObjProgramme,
    setPaginationObjProgramme,
    fetchingSessions,
    setFetchingSessions,
    scrollTo,
    setScrollTo,
  } = useStore(state => state);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const handleProgrammeCardClick = useCallback(
    programme => {
      setProgrammeClickedOn(programme);
      navigate(`/${accessCode}/locations/${programme.id}`);
    },
    [accessCode, navigate, setProgrammeClickedOn]
  );

  useEffect(() => {
    if (scrollTo && document.querySelector(`[data-id="${scrollTo}"]`)) {
      document
        .querySelector(`[data-id="${scrollTo}"]`)
        .scrollIntoView({ behavior: 'smooth' });
      setScrollTo(null);
    }
  }, []);

  const loadProgrammesFromLocalStorage = () => {
    setFetchingSessions(true);
    if (allProgrammes.length === 0) {
      setFetchingSessions(false);
      return;
    }
    setRenderedProgrammes(
      allProgrammes.slice(0, renderedProgrammes.length || LOAD_LIMIT)
    );
    setFetchingSessions(false);
  };

  const fetchAllProgrammes = useCallback(
    _accessCode => {
      if (_accessCode) {
        if (!allProgrammes || allProgrammes.length === 0)
          setFetchingSessions(true);
        axios
          .create({ baseURL: API_URL })
          .post('/Events/get-programmes', {
            data: {
              accessCode: _accessCode,
            },
          })
          .then(res => {
            const _allProgrammes = res.data;
            setAllProgrammes(_allProgrammes);
            setRenderedProgrammes(
              _allProgrammes.slice(0, renderedProgrammes.length || LOAD_LIMIT)
            );
            setFetchingSessions(false);
          })
          .catch(err => {
            console.log(err);
            setFetchingSessions(false);
          });
      }
    },
    [
      allProgrammes,
      renderedProgrammes.length,
      setAllProgrammes,
      setFetchingSessions,
      setRenderedProgrammes,
    ]
  );

  useEffect(() => {
    loadProgrammesFromLocalStorage();
    const isDeviceOnline = isOnline();
    if (isDeviceOnline)
      isDeviceOnline.then(res => {
        if (res.ok && accessCode) fetchAllProgrammes(accessCode);
      });
  }, [accessCode]);

  const renderNextBatchOfProgrammes = useCallback(() => {
    const newSkip = paginationObjProgramme.skip + LOAD_LIMIT;
    const newLimit = paginationObjProgramme.limit + LOAD_LIMIT;

    setPaginationObjProgramme({
      skip: newSkip,
      limit: newLimit,
    });
    if (newSkip > allProgrammes.length) return;
    setRenderedProgrammes([
      ...renderedProgrammes,
      ...allProgrammes.slice(newSkip, newLimit),
    ]);
  }, [
    paginationObjProgramme.skip,
    paginationObjProgramme.limit,
    setPaginationObjProgramme,
    allProgrammes,
    setRenderedProgrammes,
    renderedProgrammes,
  ]);

  if (fetchingSessions)
    return (
      <>
        {SKELETON_SESSIONS.map((session, i) => (
          <ProgrammeCardSkeleton key={i} />
        ))}
      </>
    );

  return (
    <ProgrammeList
      programmes={renderedProgrammes}
      fetchProgrammes={renderNextBatchOfProgrammes}
      onCardClick={handleProgrammeCardClick}
    />
  );
}

export default Locations;
