const noop = () => null;

export const safeGet = (getFunc = noop, defaultValue = null) => {
  try {
    return getFunc();
  } catch (e) {
    return defaultValue;
  }
}

