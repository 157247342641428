export const LOAD_LIMIT = 15;

export const SKELETON_SESSIONS = Array(5).fill({
    title: '',
    start: '',
    end: '',
    session: { title: '' },
    speakers: [],
});

export const API_URL = process.env.REACT_APP_API_URL;
