import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useStorePersisted } from '../../state/store';
import {
  Box,
  Card,
  Heading,
  Stack,
  Text,
  CardBody,
  Flex,
  Spacer,
  Center,
  IconButton,
  Divider,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import {
  HiClock,
  HiOutlineMapPin,
  HiOutlineBookmark,
  HiBookmark,
  HiUser,
} from 'react-icons/hi2';
import { clearTextFromHTML } from '../../utils/clearTextFromHTML';
import { safeGet } from '../../utils/safeGet';
import ReactGA from 'react-ga';

function SessionCard({
  session,
  onSaveCallback,
  onClick,
  showDetails,
  hideLocation,
}) {
  const toast = useToast();
  const color = safeGet(() => session.programme.options.color.hex, '#E5E5E5');
  const [isSavedSession, setIsSavedSession] = useState(false);
  const [lastTap, setLastTap] = useState(0);

  const { savedSessions, setSavedSessions, accessCode } = useStorePersisted(
    state => state
  );

  const getSpeakerText = speaker => {
    const { name, job, company } = speaker;
    return `${name}${job ? ', ' + job : ''}${company ? ', ' + company : ''}`;
  };

  const extractBodyText = html => {
    const startIndex = html.indexOf('<body>') + 6; // add 6 to skip over "<body>"
    const endIndex = html.indexOf('</body>');
    return html.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setIsSavedSession(savedSessions.some(s => s.id === session.id));
  }, []);

  const toggleSaveSession = useCallback(
    _session => {
      let savedSessionsList = savedSessions;
      if (!isSavedSession) {
        ReactGA.event({
          category: 'UserInteraction',
          action: 'Saved a session',
          label: clearTextFromHTML(session.title),
        });
        savedSessionsList.push(_session);
        setIsSavedSession(true);
        toast({
          title: 'Session saved.',
          description: (
            <Text>
              You can see all your saved sessions{' '}
              <Link to={`/${accessCode}/saved-sessions`}>here</Link>.
            </Text>
          ),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        ReactGA.event({
          category: 'UserInteraction',
          action: 'Removed a saved session',
          label: clearTextFromHTML(session.title),
        });
        savedSessionsList = savedSessionsList.filter(s => s.id !== _session.id);
        setIsSavedSession(false);
        toast({
          title: 'Session removed.',
          description: <Text>Session was succesfully removed.</Text>,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      setSavedSessions(savedSessionsList);
      onSaveCallback && onSaveCallback();
    },
    [isSavedSession, onSaveCallback, savedSessions, setSavedSessions, toast]
  );

  const handleTouchStart = () => {
    const now = Date.now();
    if (now - lastTap <= 300) {
      toggleSaveSession(session);
    }
    setLastTap(now);
  };

  // Title can contain symbols encoded like this: &#8211;, since we are not using dangerouslySetInnerHTML we have to decode them
  function decodeText(encodedString) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = encodedString;
    return textarea.value;
  }

  if (session)
    return (
      <Card
        variant="filled"
        mb={2}
        ml={2}
        mr={2}
        style={!showDetails ? { cursor: 'pointer' } : null}
        onTouchStart={handleTouchStart}
        onClick={() => {
          onClick(session);
        }}
      >
        <CardBody>
          <Stack spacing="3">
            <Flex>
              <Heading size="md">
                {decodeText(clearTextFromHTML(session.title))}
              </Heading>
              <Spacer />
              <IconButton
                ml={1}
                size="sm"
                aria-label="Save Session Button"
                fontSize="xl"
                color="current"
                onClick={e => {
                  e.stopPropagation();
                  toggleSaveSession(session);
                }}
                icon={
                  isSavedSession ? (
                    <Icon as={HiBookmark} />
                  ) : (
                    <Icon as={HiOutlineBookmark} />
                  )
                }
              />
            </Flex>
            <Divider />
            <Flex direction={['column', 'row']}>
              <Center my={2}>
                <Icon boxSize={6} as={HiClock} mr={2} />
                <Text fontSize="lg" w="100%" sx={{ lineHeight: 'normal' }}>
                  {session.start} - {session.end}
                </Text>
              </Center>
              {!hideLocation && (
                <>
                  <Spacer />
                  <Center>
                    <Icon
                      boxSize={6}
                      color={color}
                      as={HiOutlineMapPin}
                      mr={2}
                    />
                    <Text
                      fontSize="lg"
                      w="100%"
                      sx={{ lineHeight: 'normal' }}
                      color={color}
                    >
                      {clearTextFromHTML(session.programme.title)}
                    </Text>
                  </Center>
                </>
              )}
            </Flex>
            {showDetails && extractBodyText(session.description) && (
              <>
                <Divider />
                <Text
                  dangerouslySetInnerHTML={{ __html: session.description }}
                />
              </>
            )}
            <Divider />
            {!showDetails ? (
              <Box mt="2">
                {session.speakers.map(speaker => (
                  <Flex key={speaker.id}>
                    <Center>
                      <Icon as={HiUser} mr={2} />
                      <Text size="sm" w="100%" noOfLines={1}>
                        {getSpeakerText(speaker)}
                      </Text>
                    </Center>
                  </Flex>
                ))}
              </Box>
            ) : (
              <Box mt="2">
                {session.speakers.map(speaker => (
                  <Box key={speaker.id}>
                    <Heading size="sm" w="100%">
                      {speaker.name}
                    </Heading>
                    <Text size="md" w="100%">
                      {speaker.job}
                    </Text>
                    <Heading mb="3" size="sm" w="100%">
                      {speaker.company}
                    </Heading>
                  </Box>
                ))}
              </Box>
            )}
          </Stack>
        </CardBody>
      </Card>
    );
}

export default React.memo(SessionCard, (prevProps, nextProps) => {
  return prevProps.session.id === nextProps.session.id;
});
