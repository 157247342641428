import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  Box,
  Center,
  Text,
  theme,
  Fade,
  Divider,
  ScaleFade,
} from '@chakra-ui/react';

import SessionCard from '../SessionCard';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import {
  HiCalendarDays,
  HiOutlineArrowUp,
  HiOutlineClock,
  HiOutlineEllipsisHorizontalCircle,
} from 'react-icons/hi2';
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';

const _cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 300,
});

function SessionList({
  allSessions,
  scrollToIndex,
  setScrollTo,
  onCardClick,
  listHeight,
}) {
  const stickyRef = useRef();
  const [cache, setCache] = useState(_cache);
  const [stickyText, setStickyText] = useState(undefined);
  const [stickyVisible, setStickyVisible] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    setCache(
      new CellMeasurerCache({
        keyMapper: (rowIndex, columnIndex) => allSessions[rowIndex].id,
        defaultHeight: 100,
        fixedWidth: true,
      })
    );
  }, [allSessions]);

  const getStickyText = (firstSessionDate, currentSessionDate) => {
    const now = new Date();
    const eventDay = dateDiffInDays(firstSessionDate, currentSessionDate) + 1;
    if (now.toDateString() === currentSessionDate.toDateString()) {
      return 'Today';
    } else if (new Date(now.setDate(now.getDate() - 1)).toDateString() === currentSessionDate.toDateString()) {
      return 'Yesterday';
    } else if (new Date(now.setDate(now.getDate() + 2)).toDateString() === currentSessionDate.toDateString()) {
      return 'Tomorrow';
    } else {
      return `Day ${eventDay}`;
    }
  };

  //TODO:  This is for havin separator between sessions that are occuring on different dates
  const renderSticky = text => {
    return (
      <Fade in={stickyVisible} unmountOnExit={true}>
        <Box
          ref={stickyRef}
          style={{
            position: 'absolute',
            width: '100%',
            zIndex: theme.zIndices.sticky,
            display: 'block',
            marginTop: '0.3em',
          }}
        >
          <Center
            style={{
              width: 'fit-content',
              margin: 'auto',
              borderRadius: 4,
              backgroundColor: theme.colors.gray[400],
              color: theme.colors.gray[800],
              padding: '0.5rem 1rem',
              opacity: 0.9,
            }}
          >
            <Icon mr={2} boxSize={5} as={HiCalendarDays} />
            <Text fontSize="xl">{text}</Text>
          </Center>
        </Box>
      </Fade>
    );
  };

  // takes number returns string with addition of st to 1, nd to 2, rd to 3, th to 4, 5, 6, 7, 8, 9, 0 etc
  const getOrdinalNum = n => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const getMonthName = n => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'Septemper',
      'October',
      'November',
      'December',
    ];
    return months[n];
  };

  const renderSeparator = (firstSessionDate, currentSessionDate) => {
    if (!currentSessionDate || !firstSessionDate) return null;
    const text = `${getStickyText(
      firstSessionDate,
      currentSessionDate
    )} - ${getOrdinalNum(currentSessionDate.getDate())} ${getMonthName(
      currentSessionDate.getMonth()
    )}`;
    return (
      <Box
        m={2}
        mt={0}
        style={{
          borderRadius: 4,
        }}
      >
        <Center style={{ justifyContent: 'left' }}>
          <Icon mr={2} boxSize={5} as={HiCalendarDays} />
          <Text fontSize="xl">{text}</Text>
        </Center>
        <Divider />
      </Box>
    );
  };

  //function that calculates dfference between two dates
  const dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      a.getFullYear(),
      a.getMonth(),
      a.getDate(),
      a.getHours(),
      a.getMinutes()
    );
    const utc2 = Date.UTC(
      b.getFullYear(),
      b.getMonth(),
      b.getDate(),
      b.getHours(),
      b.getMinutes()
    );
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };

  const renderRow = ({ index, key, style, parent }) => {
    const session = allSessions[index];
    const firstSessionDate = new Date(allSessions[0].day);
    const currentSessionDate = new Date(session.day);
    const previousSessionDate = new Date(allSessions[index - 1]?.day);

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <Box data-id={session.id} style={style} ref={registerChild}>
            {currentSessionDate.getDate() !== previousSessionDate.getDate() &&
              renderSeparator(firstSessionDate, currentSessionDate)}
            <SessionCard
              onClick={_session => onCardClick(_session, index)}
              session={session}
            />
          </Box>
        )}
      </CellMeasurer>
    );
  };

  const timeoutId = useRef(0);
  const scrollTimeout = useRef(0);

  function setAndClearTimeout() {
    clearTimeout(timeoutId.current);
    clearTimeout(scrollTimeout.current);
    if (!stickyVisible) setStickyVisible(true);
    if (!isScrolling) setIsScrolling(true);

    scrollTimeout.current = setTimeout(() => {
      setIsScrolling(false);
    }, 100);
    timeoutId.current = setTimeout(() => {
      setStickyVisible(false);
    }, 3000);
  }

  const goToTop = () => {
    setScrollTo(0);
  };

  return (
    <div style={{ height: listHeight }}>
      {renderSticky(stickyText)}
      <AutoSizer>
        {({ width, height }) => {
          return (
            <List
              width={width}
              height={height}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={renderRow}
              estimatedRowSize={300}
              rowCount={allSessions.length}
              overscanRowCount={5}
              onRowsRendered={rows => {
                const index = rows.startIndex;
                const text = `${getStickyText(
                  new Date(allSessions[0].day),
                  new Date(allSessions[index].day)
                )} - ${getOrdinalNum(
                  new Date(allSessions[index].day).getDate()
                )} ${getMonthName(
                  new Date(allSessions[index].day).getMonth()
                )}`;
                if (text !== stickyText) setStickyText(text);
              }}
              onScroll={e => {
                if (e.scrollTop === 0) {
                  setStickyVisible(false);
                } else {
                  setAndClearTimeout();
                }
              }}
              scrollToAlignment="start"
              scrollToIndex={scrollToIndex}
            />
          );
        }}
      </AutoSizer>
      {/* <ScaleFade in={!isScrolling}>
        <Menu autoSelect={false}>
          <MenuButton
            colorScheme="blue"
            style={{
              position: 'fixed',
              bottom: 15,
              right: 15,
              borderRadius: '50%',
              padding: '1.5em',
              width: 'fit-content',
              height: 'fit-content',
            }}
            as={Button}
          >
            <HiOutlineEllipsisHorizontalCircle />
          </MenuButton>
          <MenuList
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'end',
            }}
          >
            <MenuItem
              as={Button}
              colorScheme="blue"
              rightIcon={<HiOutlineClock />}
            >
              GO TO NOW
            </MenuItem>
            <MenuItem
              onClick={goToTop}
              as={Button}
              colorScheme="blue"
              rightIcon={<HiOutlineArrowUp />}
            >
              GO TO TOP
            </MenuItem>
          </MenuList>
        </Menu>
      </ScaleFade> */}
    </div>
  );
}

export default SessionList;
