import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import _ from 'lodash';
import SessionCardSkeleton from '../../components/SessionCardSkeleton';
import SessionList from '../../components/SessionList';
import { API_URL, SKELETON_SESSIONS } from '../../constants';

import { useStore, useStorePersisted } from '../../state/store';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import isOnline from '../../utils/isOnline';

import ReactGA from 'react-ga';
import { clearTextFromHTML } from '../../utils/clearTextFromHTML';

function Programme(props) {
  const navigate = useNavigate();
  const { accessCode } = useParams();

  // Persisted state to local storage
  const {
    allSessions,
    setAllSessions,
    setAllProgrammes,
    setSessionClickedOn,
    setClient,
    client,
  } = useStorePersisted(state => state);

  // In memory state
  const { fetchingSessions, setFetchingSessions, scrollTo, setScrollTo } =
    useStore(state => state);

  const handleSessionCardClick = useCallback(
    (session, index) => {
      ReactGA.event({
        category: 'UserInteractoin',
        action: 'Clicked on a session card',
        label: clearTextFromHTML(session.title),
      });
      setSessionClickedOn(session);
      setScrollTo(index);
      navigate(`/${accessCode}/${session.id}`);
    },
    [accessCode, navigate, setScrollTo, setSessionClickedOn]
  );

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (scrollTo && document.querySelector(`[data-id="${scrollTo}"]`)) {
      document.querySelector(`[data-id="${scrollTo}"]`).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setScrollTo(undefined);
    }
  }, []);

  // function that returns true or false if the session is ongoing at the moment, takes two dates as arguments
  const isSessionOngoing = (start, end, sessionDay) => {
    const startDate = start.split(':');
    const endDate = end.split(':');
    const sessionDate = new Date(sessionDay);
    const now = new Date('2022-04-27T10:35:00.000Z').valueOf();
    const sessionStart = new Date(
      sessionDate.getFullYear(),
      sessionDate.getMonth(),
      sessionDate.getDate(),
      parseInt(startDate[0]),
      parseInt(startDate[1])
    ).valueOf();
    const sessionEnd = new Date(
      sessionDate.getFullYear(),
      sessionDate.getMonth(),
      sessionDate.getDate(),
      parseInt(endDate[0]),
      parseInt(endDate[1])
    ).valueOf();
    return sessionStart <= now && now <= sessionEnd;
  };

  const loadSessionsFromLocalStorage = () => {
    console.log('loadSessionsFromLocalStorage');
    setFetchingSessions(true);
    if (allSessions.length === 0) {
      setFetchingSessions(false);
      return;
    }
    const allSessionsSorted = _.sortBy(allSessions, [
      function (s) {
        return new Date(s.day).getTime();
      },
      'start',
    ]);
    setAllSessions(allSessionsSorted);
    setFetchingSessions(false);
  };

  const fetchAllSessions = _accessCode => {
    if (_accessCode) {
      if (!allSessions || allSessions.length === 0) setFetchingSessions(true);
      axios
        .create({ baseURL: API_URL })
        .post('/Events/get-sessions', {
          data: {
            accessCode: _accessCode,
          },
        })
        .then(res => {
          const _allSessions = _.sortBy(res.data.sessions, [
            function (s) {
              return new Date(s.day).getTime();
            },
            'start',
          ]);
          setClient(res.data.client);
          setAllProgrammes(res.data.programmes);
          setAllSessions(_allSessions);
          setFetchingSessions(false);
          console.log('fetched from server', _accessCode);
        })
        .catch(err => {
          console.log(err);
          setFetchingSessions(false);
        });
    }
  };

  useEffect(() => {
    console.log(client);
  }, [client]);

  useEffect(() => {
    loadSessionsFromLocalStorage();
    const isDeviceOnline = isOnline();
    if (isDeviceOnline)
      isDeviceOnline.then(res => {
        if (res.ok && accessCode) fetchAllSessions(accessCode);
      });
  }, [accessCode]);

  if (fetchingSessions)
    return (
      <>
        {SKELETON_SESSIONS.map((session, i) => (
          <SessionCardSkeleton key={i} />
        ))}
      </>
    );
  const height = props.navigationRef.current
    ? props.navigationRef.current.offsetHeight
    : 50;
  return (
    <SessionList
      onCardClick={handleSessionCardClick}
      allSessions={allSessions}
      scrollToIndex={scrollTo}
      setScrollTo={setScrollTo}
      listHeight={`calc(100vh - ${height}px)`}
    />
  );
}

export default Programme;
