import React, { useRef } from 'react';
import {
  ChakraProvider,
  GridItem,
  Divider,
  Grid,
  Heading,
  Flex,
  Spacer,
  Container,
  Text,
  theme,
  useToast,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import TabNavigation from './components/TabNavigation';
import { useEffect } from 'react';
import { useStorePersisted } from './state/store';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import customTheme from "./theme";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function App({ children }) {
  const toast = useToast();
  const navigationRef = useRef(null);
  const { accessCode } = useParams();
  const { theme: themeMode, setAccessCode, savedSessions, client } = useStorePersisted(state => state);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (savedSessions?.length) {
      savedSessions.forEach(session => {
        const sessionDay = new Date(session.day);
        const sessionStartTime = session.start.split(':');
        const sessionDate = new Date(
          sessionDay.getFullYear(),
          sessionDay.getMonth(),
          sessionDay.getDate(),
          Number(sessionStartTime[0]),
          Number(sessionStartTime[1])
        );
        const currentTime = new Date();
        if (sessionDate.getTime() - currentTime.getTime() < 0) return undefined;
        if (sessionDate.getTime() - currentTime.getTime() <= 5 * 60 * 1000)
          toast({
            title: 'Session you saved is about to start.',
            description: <Text noOfLines={2}>{session.title}</Text>,
            status: 'info',
            duration: 10000,
            isClosable: true,
            position: 'top-right',
          });
      });
    }
  });

  useEffect(() => {
    if (accessCode)
      setAccessCode(accessCode);
  }, [accessCode, setAccessCode]);

  return (
    <ChakraProvider theme={customTheme}>
      <Helmet>
        <title>{client?.username ? client.username + ' | Eventignite Web App Companion' : 'Eventignite Web App Companion'}</title>
      </Helmet>
      <Container
        p={0}
        width={[
          '100%', // sm
          '100%', // md
          '75%', // lg
          '50%', // xl
        ]}
      >
        <Grid gap="2" ref={navigationRef} style={{ position: 'sticky', top: 0, zIndex: theme.zIndices.sticky, backgroundColor: themeMode === 'light' ? theme.colors.white : theme.colors.gray[800] }} >
          <GridItem>
            <Flex p="2" justifyContent="center">
              <Heading size="lg" style={{ display: 'flex', alignItems: 'center' }}>{client?.username ? client.username : 'EventIgnite'}</Heading>
              <Spacer />
              <ColorModeSwitcher justifySelf="flex-end" />
            </Flex>
            <Divider />
          </GridItem>
          <GridItem>
            <TabNavigation />
          </GridItem>
        </Grid>
        {React.cloneElement(children, { navigationRef })}
      </Container>
    </ChakraProvider>
  );
}

export default App;
