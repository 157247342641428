import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { ScaleFade } from '@chakra-ui/react';

import ProgrammeCard from '../ProgrammeCard';

function ProgrammeList({ programmes, fetchProgrammes, onCardClick }) {
  const observer = useRef();

  const lastSessionElementRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          fetchProgrammes();
        }
      });
      if (node) observer.current.observe(node);
    },
    [fetchProgrammes]
  );
  return (
    <>
      {programmes.map((programme, i) => {
        const isLastProgramme = programmes.length === i + 1;
        if (isLastProgramme)
          return (
            <ScaleFade ref={lastSessionElementRef} key={programme.id} in>
              <ProgrammeCard
                programme={programme}
                onClick={programme => {
                  onCardClick(programme);
                }}
              />
            </ScaleFade>
          );
        return (
          <ScaleFade key={programme.id} in>
            <ProgrammeCard
              onClick={programme => {
                onCardClick(programme);
              }}
              programme={programme}
            />
          </ScaleFade>
        );
      })}
    </>
  );
}

export default ProgrammeList;
