import { Flex, IconButton, Icon, Text, Spinner } from '@chakra-ui/react';
import { HiChevronLeft } from 'react-icons/hi2';

function GoBackButton({ handleClick, loading }) {
  return (
    <Flex
      mb="3"
      ml="2"
      alignItems="center"
      onClick={e => {
        e.stopPropagation();
        handleClick();
      }}
    >
      <IconButton
        size="sm"
        fontSize="xl"
        color="current"
        onClick={e => {
          e.stopPropagation();
          handleClick();
        }}
        icon={<Icon as={HiChevronLeft} />}
      />
      <Text style={{ cursor: 'pointer' }} marginLeft="3">
        Back
      </Text>
    </Flex>
  );
}

export default GoBackButton;
