import React, { useEffect, useRef } from 'react';
import { Divider, Heading, Box } from '@chakra-ui/react';
import GoBackButton from '../../components/GoBackButton/GoBackButton';

import SessionList from '../../components/SessionList/SessionList';

import { useStore, useStorePersisted } from '../../state/store';

import { useNavigate } from 'react-router-dom';
import { clearTextFromHTML } from '../../utils/clearTextFromHTML';
import ReactGA from 'react-ga';

const LOAD_LIMIT = 15;

function LocationDetails(props) {
  const allSessions = useStorePersisted(state => state.allSessions);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const programmeClickedOn = useStorePersisted(
    state => state.programmeClickedOn
  );

  const setSessionClickedOn = useStorePersisted(
    state => state.setSessionClickedOn
  );

  const renderedProgrammeSessions = useStore(
    state => state.renderedProgrammeSessions
  );
  const setRenderedProgrammeSessions = useStore(
    state => state.setRenderedProgrammeSessions
  );

  const navigate = useNavigate();

  const loadProgrammeSessionsFromLocalStorage = () => {
    setRenderedProgrammeSessions(
      allSessions
        .filter(s => s.programme.title === programmeClickedOn.title)
        .slice(0, renderedProgrammeSessions.length || LOAD_LIMIT)
    );
  };

  useEffect(() => {
    loadProgrammeSessionsFromLocalStorage();
  }, []);

  const titleRef = useRef(null);
  const height =
    props.navigationRef.current && titleRef.current
      ? props.navigationRef.current.offsetHeight + titleRef.current.offsetHeight
      : 50;

  return (
    <>
      <Box ref={titleRef}>
        <GoBackButton handleClick={() => navigate(-1)} />
        <Heading ml={3} size="lg" mr={4} mb="4">
          {clearTextFromHTML(programmeClickedOn.title)}
        </Heading>
        <Divider />
      </Box>
      <SessionList
        onCardClick={session => {
          setSessionClickedOn(session);
          navigate(`${session.id}`);
        }}
        allSessions={allSessions.filter(
          s => s.programme.title === programmeClickedOn.title
        )}
        listHeight={`calc(100vh - ${height}px)`}
      />
    </>
  );
}

export default LocationDetails;
