import { Card, CardBody, SkeletonText } from '@chakra-ui/react';

function ProgrammeCardSkeleton() {
  const animationSpeed = 2;
  const getNumberOfLines = (min, max) => min + Math.round(Math.random() * max);

  return (
    <Card variant="filled" mb={2} style={{ cursor: 'pointer' }}>
      <CardBody>
        <SkeletonText
          noOfLines={getNumberOfLines(1, 1)}
          spacing="2"
          skeletonHeight="4"
          speed={animationSpeed}
        />
      </CardBody>
    </Card>
  );
}

export default ProgrammeCardSkeleton;
