import { Card, Text, CardBody, Center } from '@chakra-ui/react';
import { clearTextFromHTML } from '../../utils/clearTextFromHTML';
import { safeGet } from '../../utils/safeGet';

function ProgrammeCard({ programme, onClick }) {
  const color = safeGet(() => programme.options.color.hex, '#E5E5E5');
  return (
    <Card
      ml={4}
      mr={4}
      onClick={() => {
        onClick(programme);
      }}
      variant="filled"
      mb={2}
      style={{
        cursor: 'pointer',
        borderColor: color,
        borderLeftWidth: '2px',
      }}
    >
      <CardBody>
        <Center>
          <Text fontSize="lg" w="100%">
            {clearTextFromHTML(programme.title)}
          </Text>
        </Center>
      </CardBody>
    </Card>
  );
}

export default ProgrammeCard;
