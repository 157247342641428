import React from 'react';
import { Text, Center } from '@chakra-ui/react';

import SessionList from '../../components/SessionList';

import { useStore, useStorePersisted } from '../../state/store';

import { useParams, useNavigate } from 'react-router-dom';

function Saved() {
  const savedSessions = useStorePersisted(state => state.savedSessions);

  const history = useNavigate();
  const { accessCode } = useParams();
  const { setSessionClickedOn, scrollTo } = useStore(state => state);

  if (!savedSessions.length)
    return (
      <Center>
        <Text>There are no saved saved sessions.</Text>
      </Center>
    );

  return (
    <SessionList
      onCardClick={session => {
        setSessionClickedOn(session);
        history(`/${accessCode}/saved-sessions/${session.id}`);
      }}
      allSessions={savedSessions}
      scrollToIndex={scrollTo}
      listHeight={'calc(100vh - 125px)'}
    />
  );
}

export default Saved;
