import React, { useEffect } from 'react';

import SessionCard from '../../components/SessionCard/SessionCard';
import GoBackButton from '../../components/GoBackButton/GoBackButton';

import { useStorePersisted } from '../../state/store';
import { useNavigate } from 'react-router-dom';
import { ScaleFade } from '@chakra-ui/react';
import ReactGA from 'react-ga';

function SessionDetails({ onClickBack }) {
  const navigate = useNavigate();

  const savedSessions = useStorePersisted(state => state.savedSessions);
  const setSavedSessions = useStorePersisted(state => state.setSavedSessions);
  const session = useStorePersisted(state => state.sessionClickedOn);

  const handleClickGoBack = () => {
    if (onClickBack) onClickBack();
    else navigate(-1);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (session)
    return (
      <>
        <GoBackButton handleClick={handleClickGoBack} />
        <ScaleFade in>
          <SessionCard
            savedSessions={savedSessions}
            setSavedSessions={setSavedSessions}
            onClick={() => {}}
            session={session}
            showDetails={true}
          />
        </ScaleFade>
      </>
    );
}

export default SessionDetails;
