import { ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import Programme from './pages/Programme/Programme';
import Locations from './pages/Locations/Locations';
import Saved from './pages/Saved';
import SessionDetails from './pages/SessionDetails';

import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import LocationDetails from './pages/LocationDetails/LocationDetails';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const router = createBrowserRouter([
  {
    path: '/event/:accessCode',
    element: (
      <>
        <ColorModeScript />
        <App>
          <Programme />
        </App>
      </>
    ),
  },
  {
    path: '/',
    element: (
      <>
        <ColorModeScript />
        <App>
          <Programme />
        </App>
      </>
    ),
  },
  {
    path: '/:accessCode',
    element: (
      <>
        <ColorModeScript />
        <App>
          <Programme />
        </App>
      </>
    ),
  },
  {
    path: '/:accessCode/locations',
    element: (
      <>
        <ColorModeScript />
        <App>
          <Locations />
        </App>
      </>
    ),
  },
  {
    path: '/:accessCode/saved-sessions',
    element: (
      <>
        <ColorModeScript />
        <App>
          <Saved />
        </App>
      </>
    ),
  },
  {
    path: '/:accessCode/:sessionId',
    element: (
      <>
        <ColorModeScript />
        <App>
          <SessionDetails />
        </App>
      </>
    ),
  },
  {
    path: '/:accessCode/locations/:locationId',
    element: (
      <>
        <ColorModeScript />
        <App>
          <LocationDetails />
        </App>
      </>
    ),
  },
  {
    path: '/:accessCode/saved-sessions/:sessionId',
    element: (
      <>
        <ColorModeScript />
        <App>
          <SessionDetails />
        </App>
      </>
    ),
  },
  {
    path: '/:accessCode/locations/:locationId/:sessionId',
    element: (
      <>
        <ColorModeScript />
        <App>
          <SessionDetails />
        </App>
      </>
    ),
  },
]);


root.render(<RouterProvider router={router} />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
