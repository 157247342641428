import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { LOAD_LIMIT } from '../constants';
import { mountStoreDevtool } from 'simple-zustand-devtools';

const useStorePersisted = create(
  persist(
    set => ({
      theme: 'dark',
      client: {},
      setClient: _client => set({ client: _client }),
      setTheme: _theme => set({ theme: _theme }),
      allSessions: [],
      setAllSessions: sessions => set({ allSessions: sessions }),
      allProgrammes: [],
      setAllProgrammes: programmes => set({ allProgrammes: programmes }),
      accessCode: undefined,
      setAccessCode: code => set({ accessCode: code }),
      savedSessions: [],
      setSavedSessions: sessions => set({ savedSessions: sessions }),
      sessionClickedOn: undefined,
      setSessionClickedOn: session => set({ sessionClickedOn: session }),
      programmeClickedOn: undefined,
      setProgrammeClickedOn: programme =>
        set({ programmeClickedOn: programme }),
    }),
    { name: 'EVENTIGNITE_COMPANION', getStorage: () => localStorage }
  )
);

const useStore = create(set => ({
  renderedProgrammeSessions: [],
  setRenderedProgrammeSessions: sessions => set({ renderedProgrammeSessions: sessions }),
  paginationObjProgrammeSessions: { skip: 0, limit: LOAD_LIMIT },
  setPaginationObjProgrammeSessions: obj => set({ paginationObjProgrammeSessions: obj }),
  renderedSessions: [],
  setRenderedSessions: sessions => set({ renderedSessions: sessions }),
  paginationObjSession: { skip: 0, limit: LOAD_LIMIT },
  setPaginationObjSession: obj => set({ paginationObjSession: obj }),
  renderedProgrammes: [],
  setRenderedProgrammes: programmes => set({ renderedProgrammes: programmes }),
  paginationObjProgramme: { skip: 0, limit: LOAD_LIMIT },
  setPaginationObjProgramme: obj => set({ paginationObjProgramme: obj }),
  fetchingProgrammes: true,
  setFetchingProgrammes: bool => set({ fetchingProgrammes: bool }),
  fetchingSessions: true,
  setFetchingSessions: bool => set({ fetchingSessions: bool }),
  fetchingSavedSessions: true,
  setFetchingSavedSessions: bool => set({ fetchingSavedSessions: bool }),
  scrollTo: undefined,
  setScrollTo: id => set({ scrollTo: id }),
  offlineMode: false,
  setOfflineMode: bool => set({ offlineMode: bool }),
}));

mountStoreDevtool('Store', useStore);

export { useStore, useStorePersisted };
