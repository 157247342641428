import {
  Card,
  Stack,
  Text,
  CardBody,
  Flex,
  Spacer,
  Center,
  SkeletonText,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { HiClock, HiOutlineMapPin, HiUser } from 'react-icons/hi2';

function SessionCardSkeleton() {
  const animationSpeed = 2;
  const getNumberOfLines = (min, max) => min + Math.round(Math.random() * max);

  return (
    <Card variant="filled" mb={2} style={{ cursor: 'pointer' }}>
      <CardBody>
        <Stack spacing="3">
          <SkeletonText
            noOfLines={getNumberOfLines(1, 2)}
            spacing="2"
            skeletonHeight="4"
            speed={animationSpeed}
          />
          <Flex>
            <Center>
              <Icon as={HiClock} mr={2} />
              <SkeletonText
                speed={animationSpeed}
                noOfLines={1}
                style={{ width: '50px' }}
              />
              <Text style={{ marginRight: '10px', marginLeft: '10px' }}>-</Text>
              <SkeletonText
                speed={animationSpeed}
                noOfLines={1}
                style={{ width: '50px' }}
              />
            </Center>
            <Spacer />
            <Center>
              <Icon as={HiOutlineMapPin} mr={2} />
              <SkeletonText
                speed={animationSpeed}
                noOfLines={1}
                style={{ width: '150px' }}
              />
            </Center>
          </Flex>
          {Array(getNumberOfLines(1, 3))
            .fill('')
            .map((item, i) => (
              <Center key={i}>
                <Icon as={HiUser} mr={2} />
                <SkeletonText
                  size="sm"
                  w="100%"
                  noOfLines={1}
                  style={{ width: '100%' }}
                />
              </Center>
            ))}
        </Stack>
      </CardBody>
    </Card>
  );
}

export default SessionCardSkeleton;
